/**
 * Ce script gère la disposition des images en responsive
 * si la width est inférieure à 991px on n'affiche plus qu'une seule image
 *
 * En outre on profite de ce script pour rendre le chevronUp responsive.
 */

$(function () {

    responsive();

     $(window).on('resize', function () {
         responsive();
     });

 });

 function responsive() {
     if (window.innerWidth <= 991 && window.innerWidth > 767) {
         /**
          * gestion des images en responsive
          */
         $('.firstImg').removeClass('grid-item--width3');
         $('.firstImg').removeClass('grid-item--width9');
         $('.firstImg').addClass('grid-item--width6');
         $('.otherImg').css('display', 'none');
         /**
          * gestion du chevronUp en responsive
          */
         $('.top-link').css({
             'right': '1rem',
             'padding': '0.5rem',
         });
     } else if (window.innerWidth <= 767) {
         /**
          * gestion des images en responsive
          */
         $('.firstImg').removeClass('grid-item--width3');
         $('.firstImg').removeClass('grid-item--width6');
         $('.firstImg').addClass('grid-item--width9');
         $('.otherImg').css('display', 'none');
     } else {
         /**
          * gestion des images en responsive
          */
         $('.firstImg').removeClass('grid-item--width6');
         $('.firstImg').removeClass('grid-item--width9');
         $('.firstImg').addClass('grid-item--width3');
         $('.otherImg').css('display', '');
         /**
          * gestion du chevronUp en responsive
          */
         $('.top-link').css({
             'right': '2rem',
             'padding': '1rem',
         });
     }
 }
