// Base
require('./bootstrap');

/**
 * BACK-OFFICE
 */
require('jqueryui');
require('jquery-knob');
require('bootstrap-fileinput/js/fileinput.js');
require('bootstrap-fileinput/js/locales/fr.js');
require('overlayscrollbars');
require('admin-lte');
require('admin-lte/plugins/summernote/summernote-bs4.min.js');

/**
 * FRONT-OFFICE
 */
require('./slider');
require('./slick');
require('./masonry');
require('./responsive.js');

// Base
require('alpinejs');
