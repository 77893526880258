$(function () {

    $('body > footer').append('<a href="#top" class="top-link" title="Revenir en haut"><i class="fas fa-chevron-up"></i></a>');

    $('.top-link').css({
        'position': 'fixed',
        'right': '2rem',
        'bottom': '5rem',
        'display': 'none',
        'padding': '1rem',
        'border': '1px transparent solid',
        'background': 'lightgrey',
        '-moz-border-radius': '40px',
        '-webkit-border-radius': '40px',
        'border-radius': '40px',
        'opacity': '0.9',
        'z-index': '2000'
    });

    $('.top-link').children().css({
        'color': '#01B4E1',
    });

    $(window).on('scroll', function () {
        posScroll = $(document).scrollTop();
        if (posScroll >= 250)
            $('.top-link').fadeIn(400);
        else
            $('.top-link').fadeOut(400);
    });

    $('.top-link').on('mouseover', function () {
        $(this).css({
            'borderColor': '#FF7F00',
            'color': '#FF7F00',
        })
        $(this).children().css({
            'color': '#FF7F00',
        })
    });

    $('.top-link').on('mouseleave', function () {
        $(this).css({
            'borderColor': 'transparent',
        })
        $(this).children().css({
            'color': '#01B4E1',
        })
    });

});
