$(function () {

    /**
     * SLIDER PARAMS
     * Le responsive utilisé doit être celui intégrer au module 'slick'
     */
    $('.autoplay').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplaySpeed: 1500,
                }
            }
        ]
    });

    hoverCaption();

});


/**
 * FIGCAPTIONS
 */
function hoverCaption() {
    var elements = document.querySelectorAll('.element');
    //     console.log(elements);
    for (var i = 0; i < elements.length; i++) {
        $(elements[i]).on("mouseenter", function () {
            $(this.lastElementChild).stop().slideToggle("slow");
        });
        $(elements[i]).on("mouseleave", function () {
            $(this.lastElementChild).stop().slideToggle("slow");
        });
    }
};
